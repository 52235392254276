
































import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import FinancialAccounting from '../../components/core/accounting/financial/FinancialAccounting.vue'
import {
  AccountingFinancial,
  ACCOUNTING_STATES,
} from '@/model/AccountingFinancial'
import { Project } from '@/model/Project'
import BaseButton from '@/components/base/BaseAddButton.vue'
import { AccountingExpenses } from '@/model/AccountingExpenses'
import { AccountingIncomes } from '@/model/AccountingIncomes'
import { AccountingLoans } from '@/model/AccountingLoans'
import moment from 'moment'
import { AccountingCautions } from '@/model/AccountingCautions'
import Utils from '@/utils/Utils'

@Component({
  components: { FinancialAccounting },
})
export default class ViewAccounting extends Vue {
  projectId!: number
  year!: number
  @Ref('accountings') accountingsEl: any

  get accounting(): AccountingFinancial {
    return this.$store.getters.accounting
  }
  get mainProject(): Project {
    return this.$store.getters.projects.find((p: Project) => p.main)
  }
  get state(): string {
    return this.accounting.state === ACCOUNTING_STATES.LOCKED
      ? '(Verrouillé)'
      : ''
  }

  goPreviousYear() {
    this.$router.push(`${this.year - 1}`)
  }
  goNextYear() {
    this.$router.push(`${this.year + 1}`)
  }
  backToYears() {
    this.$router.push(`/project/${this.projectId}/accounting`)
  }

  get loans(): AccountingLoans[] {
    return this.$store.getters.loans
  }
  get expenses(): AccountingExpenses[] {
    return this.$store.getters.expenses
  }
  get incomes(): AccountingIncomes[] {
    return this.$store.getters.incomes
  }
  get cautions(): AccountingCautions[] {
    return this.$store.getters.cautions.filter(
      (caution: AccountingCautions) => {
        const yearDeb = Utils.UTCToYear(caution.dateDebutLocation)
        if (yearDeb && +yearDeb > this.year) {
          return false
        }
        const dateFin = Utils.UTCToYear(caution.dateFinLocation)
        if (dateFin && +dateFin < this.year) {
          return false
        }
        return true
      },
    )
  }

  sortedExpenses: AccountingExpenses[] = []
  sortedLoans: AccountingLoans[] = []
  sortedIncomes: AccountingIncomes[] = []
  sortedCautions: AccountingCautions[] = []

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    const id = +this.$route.params.id
    const year = +this.$route.params.year
    this.projectId = id
    this.year = year

    this.$store.dispatch('getProject', this.projectId)
    this.$store.dispatch('getProjects')
    this.$store.dispatch('getTva', this.projectId)

    this.$store
      .dispatch('getAccountingByYear', {
        projectId: this.projectId,
        year: year,
      })
      .then(json => {
        if (json.success && json.data && json.data.length) {
          this.$store.dispatch('getExpenses', this.accounting.id).then(() => {
            this.sortedExpenses = this.expenses.sort((a, b) => {
              if (!a.date && b.date) return 1
              if (!b.date && a.date) return -1
              return moment(a.date).isAfter(moment(b.date)) ? 1 : -1
            })
          })
          this.$store.dispatch('getIncomes', this.accounting.id).then(() => {
            this.sortedIncomes = this.incomes.sort((a, b) => {
              if (!a.datePaiement && b.datePaiement) return 1
              if (!b.datePaiement && a.datePaiement) return -1
              return moment(a.datePaiement).isAfter(moment(b.datePaiement))
                ? 1
                : -1
            })
          })
          this.$store.dispatch('getLoans', this.accounting.id).then(() => {
            this.sortedLoans = this.loans.sort((a, b) => {
              if (!a.month && b.month) return 1
              if (!b.month && a.month) return -1
              return moment(a.month).isAfter(moment(b.month)) ? 1 : -1
            })
          })
          this.$store.dispatch('getCautions', this.projectId).then(() => {
            this.sortedCautions = this.cautions
          })
          this.$store.dispatch('getUserProject', this.projectId)
        } else {
          this.$store.dispatch('toaster/toast', {
            message: `L'année ${year} n'existe pas`,
            type: 'warning',
          })
          this.goBack()
        }
      })
  }

  sort() {
    this.sortedExpenses = this.expenses.sort((a, b) => {
      if (!a.date && b.date) return 1
      if (!b.date && a.date) return -1
      return moment(a.date).isAfter(moment(b.date)) ? 1 : -1
    })

    this.sortedIncomes = this.incomes.sort((a, b) => {
      if (!a.datePaiement && b.datePaiement) return 1
      if (!b.datePaiement && a.datePaiement) return -1
      return moment(a.datePaiement).isAfter(moment(b.datePaiement)) ? 1 : -1
    })

    this.sortedLoans = this.loans.sort((a, b) => {
      if (!a.month && b.month) return 1
      if (!b.month && a.month) return -1
      return moment(a.month).isAfter(moment(b.month)) ? 1 : -1
    })

    this.sortedCautions = this.cautions
  }

  beforeDestroy() {
    this.accountingsEl.saveAll()
  }

  goBack() {
    this.$router.back()
  }
}
